import React from 'react';
import { Redirect } from 'react-router-dom';

import Pagination from '../Helpers/Pagination';
import Table from '../Helpers/Table';
import Search from '../Helpers/Search';
import Create from '../Helpers/Create';

const Entries = (props) => {
  const { 
    searchFunction,
    specialSearch,
    filterFunctions,
    filters,
    createFunction,
    defaultValues,
    requestData, 
    requestCount, 
    redirectLink, 
    headCells, 
    rowProps,
  } = props;

  const [entries, setEntries] = React.useState([]);
  const [search, setSearch] = React.useState('');
  const [entryId, setEntryId] = React.useState(null);

  if (entryId) {
    return (
      <Redirect push to={`${redirectLink}/${entryId}`} />
    )
  }

  return (
    <main>
      <Search onChange={setEntries} onSearch={setSearch} searchFunction={searchFunction} filters={filters} />

      { specialSearch
        ? specialSearch
        : null
      }

      { createFunction 
        ? <Create redirectLink={redirectLink} createFunction={createFunction} defaultValues={defaultValues} />
        : null
      }

      <Table headCells={headCells} rowProps={rowProps} data={entries} onChange={setEntries} linkTo={setEntryId} />

      <Pagination onChange={setEntries} search={search} request={requestData} requestCount={requestCount} filterFunctions={filterFunctions} filters={filters} />
    </main>
  )
}

export default Entries;