import axios from "axios";
// const baseUrl = "http://127.0.0.1:3001";
const baseUrl = "https://www.tlmethodapi.com";

const getToken = () => {
  const token = localStorage.getItem("tl-method-token");
  return token || null;
};

export default class Ajax {
  // Files
  static async uploadFile(file, bucket, fileName = null) {
    try {
      const token = getToken();

      const formData = new FormData();
      formData.append("file", file);

      const res = await axios.post(
        `${baseUrl}/images/${bucket}/${fileName}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return res.data;
    } catch (e) {
      throw e;
    }
  }

  static async deleteFile(fileName, bucket) {
    try {
      const token = getToken();

      const res = await axios.delete(
        `${baseUrl}/images/${bucket}/${fileName}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return res.data;
    } catch (e) {
      throw e;
    }
  }

  // Special
  static async getWorkoutsBetween(start, end) {
    try {
      const token = getToken();

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const body = {
        start,
        end,
      };

      const res = await axios.post(`${baseUrl}/workouts/between/`, body, {
        headers,
      });
      return res?.data || [];
    } catch (e) {
      throw e;
    }
  }

  // User
  static async login(body) {
    try {
      const res = await axios.post(`${baseUrl}/users/admin-login`, body);

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static async createUser(body) {
    try {
      const res = await axios.post(`${baseUrl}/users/`, body);

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static async getCurrentUser() {
    try {
      const token = localStorage.getItem("tl-method-token");

      const res = await axios(`${baseUrl}/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  // Search
  static async search(model, query) {
    try {
      const token = getToken();

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(
        `${baseUrl}/${model}/search/?query=${query}`,
        { headers }
      );
      return res?.data || [];
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // Count
  static async count(model) {
    try {
      const token = getToken();

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(`${baseUrl}/${model}/count`, { headers });
      return res?.data?.count || 0;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // Get
  static async get(model, id) {
    try {
      const token = getToken();

      if (!token) return null;

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const path = id ? `${baseUrl}/${model}?id=${id}` : `${baseUrl}/${model}`;

      const res = await axios(path, { headers });
      return res?.data?.length ? res.data[0] : res.data || null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static async getAll(model, startIndex = -1, perPage = 25) {
    try {
      const token = getToken();

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(
        `${baseUrl}/${model}/all?index=${startIndex}&per=${perPage}`,
        { headers }
      );

      return res?.data || [];
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // Put
  static async update(model, id, body) {
    try {
      const token = getToken();

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const path = id ? `${baseUrl}/${model}/${id}` : `${baseUrl}/${model}`;

      const res = await axios.put(path, body, { headers });
      return res.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // Create
  static async create(model, body = {}) {
    try {
      const token = getToken();

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.post(`${baseUrl}/${model}`, body, { headers });
      return res.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // Destroy
  static async delete(model, id) {
    try {
      const token = getToken();

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const path = id ? `${baseUrl}/${model}/${id}` : `${baseUrl}/${model}`;

      const res = await axios.delete(path, { headers });
      return res.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}
