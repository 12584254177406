import React from 'react';
import { useDispatch } from 'react-redux';
import { setAllExercises } from '../../redux/actions';
import { useDeviceStyles, useHandleError } from '../../customHooks';
import Ajax from '../../Ajax';

import { Typography, Tooltip, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import GroupButtons from './GroupButtons';
import Exercise from './Exercise';
import ExerciseInput from './ExerciseInput';

import { styles } from './styles';
import { useDebounce } from 'react-use';

const typeOptions = [
  { label: 'seconds', prop: 'seconds' },
  { label: 'minutes', prop: 'minutes' },
]

const Group = ({ group, groupLength, pageIndex, onDelete, triggerSave, resetSave, handleReorder }) => {
  const dispatch = useDispatch();
  const classes = useDeviceStyles({ styles });
  const handleError = useHandleError(dispatch);

  const [exerciseDetails, setExercises] = React.useState([]);
  const [groupDetails, setDetails] = React.useState({
    description: '',
    timer: 0,
    rest: 0,
    timerType: '',
    iterations: 1,
  });

  const handleSave = React.useCallback(async () => {
    try {
      await Ajax.update('groups', group.id, {
        ...group,
        description: groupDetails.description,
        timer: groupDetails.timerType === 'minutes' ? groupDetails.timer * 60 : groupDetails.timer,
        data: exerciseDetails.filter(group => Boolean(group)),
        extraData: group.extraData
          ? {...group.extraData, iterations: groupDetails.iterations, rest: groupDetails.rest}
          : { iterations: groupDetails.iterations, rest: groupDetails.rest },
      });

    } catch (e) {
      handleError(e);
    }
  }, [exerciseDetails, group, groupDetails, handleError]);

  // Sets first time exercise store for Group to reference
  React.useEffect(() => {
    const storeExercises = async () => {
      const exes = await Ajax.getAll('exercises');
      dispatch(setAllExercises(exes));
    }

    storeExercises();
  }, [dispatch]);

  React.useEffect(() => {
    if (group.data) setExercises(group.data);

    setDetails({
      description: group?.description || '',
      timer: !group.timer
        ? 0
        : (group.timer > 60 ? parseInt(group.timer) / 60 : group.timer),
      timerType: !group.timer
        ? ''
        : (group.timer > 60 ? 'minutes' : 'seconds'),
      rest: group?.extraData?.rest,
      iterations: group?.extraData?.iterations || 1,
    });
  }, [group])

  // Manually trigger save from parent
  React.useEffect(() => {
    const saveGroup = async () => {
      await handleSave();
      resetSave();
    }

    if (triggerSave) {
      saveGroup();
    }
  }, [handleSave, resetSave, triggerSave]);

  // Triggers auto save on edits
  useDebounce(handleSave, 200, [groupDetails, exerciseDetails, handleSave]);

  // Adds empty row to state
  const handleAdd = async (details={}) => {
    try {
      setExercises(prevState => [...prevState, details]);

    } catch (e) {
      handleError(e);
    }
  }

  const handleGroupUpdate = (name, value) => {
    setDetails(prevState => {
      return {
        ...prevState,
        [name]: value
      }
    })
  }

  const handleChange = (key, name, data) => {
    const update = exerciseDetails.map((ex, i) => {
      if (i === key) {
        return { ...ex, ...{ [name]: data }};
      }

      return ex;
    });

    setExercises(update);
  }

  const handleRemove = async (index) => {
    try {
      const update = exerciseDetails.map((ex, i) => {
        if (i !== index) return ex;
        return null;
      }).filter(ex => !!ex);

      const res = await Ajax.update('groups', group.id, {
        ...group,
        data: update,
      });

      setExercises(res.data);
    } catch (e) {
      handleError(e);
    }
  }

  const handlePaste = () => {
    let details = localStorage.getItem('tl-method-copied-exercise');
    details = details ? JSON.parse(details) : null;

    if (details) handleAdd(details);
  }

  const handleCopyAll = () => {
    localStorage.setItem('tl-method-copied-set', JSON.stringify(exerciseDetails));
  }

  const handlePasteAll = () => {
    let details = localStorage.getItem('tl-method-copied-set');
    details = details ? JSON.parse(details) : null;

    if (details) {
      setExercises(details);
    }

  }

  const handleMove = (dir) => {
    const { index } = group.extraData;

    if (index === 1 && dir === -1) return;
    if (index === groupLength && dir === 1) return;

    handleReorder(index, index + dir);
  }

  return (
    <div className={classes.set}>
      <div className={classes.titleRow}>
        <Typography variant='h5'>Set {group.extraData?.index || pageIndex}</Typography>

        <GroupButtons
          handleDelete={onDelete}
          handleCopyAll={handleCopyAll}
          handlePaste={handlePaste}
          handlePasteAll={handlePasteAll}
          handleMove={handleMove}
        />

      </div>

      <div className={classes.titleRow}>
        <TextField multiline id='Description' placeholder='Description' value={groupDetails.description} name='description' onChange={(e) => handleGroupUpdate(e.target.name, e.target.value)} />
        <TextField type='number' id='Iterations' label='Iterations' value={groupDetails.iterations} name='iterations' onChange={(e) => handleGroupUpdate(e.target.name, parseInt(e.target.value))} />
      </div>

      <div className={classes.titleRow}>
        <TextField type='number' id='Timer' label='Timer' value={groupDetails.timer} name='timer' onChange={(e) => handleGroupUpdate(e.target.name, parseInt(e.target.value))} />
        <ExerciseInput name='timerType' type='select' width={100} label='type' value={groupDetails.timerType} onChange={handleGroupUpdate} options={typeOptions} />
      </div>

      <div className={classes.titleRow}>
        <TextField type='number' id='Rest' label='Rest Time' value={groupDetails.rest} name='rest' onChange={(e) => handleGroupUpdate(e.target.name, parseInt(e.target.value))} />
      </div>

      {exerciseDetails.map((row, i) => (
        <Exercise key={i} details={{ ...row, key: i }} onChange={handleChange} onDelete={handleRemove} />
      ))}

      <Tooltip title='Add New Exercise' placement='top'>
        <AddIcon onClick={() => handleAdd(null)} className={classes.icon} />
      </Tooltip>

    </div>
  )
}

export default Group;
