import React from 'react';
import Ajax from '../../Ajax';

import Entries from '../../Components/Entries';

const Users = () => {
  const headCells = ['ID', 'Email', 'Name', 'Memberful ID', 'Admin', 'Active'];
  const rowProps = ['id', 'email', 'name', 'memberfulId', 'isAdmin', 'isActive'];

  return (
    <Entries 
      headCells={headCells}
      rowProps={rowProps}
      searchFunction={(query) => Ajax.search('users', query)} 
      requestData={(start, perPage) => Ajax.getAll('users', start, perPage)} 
      requestCount={() => Ajax.count('users')} 
      redirectLink='users'
    />
  )

}

export default Users;