import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";

import { setNotification, setAllExercises } from "../../redux/actions";
import { useDeviceStyles, useHandleError } from "../../customHooks";
import Ajax from "../../Ajax";

import { Typography, Button, TextField, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import Loading from "../../Components/Helpers/Loading";
import FilePicker from "../../Components/Helpers/FilePicker";

const styles = {
  main: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    width: "100%",
    maxWidth: "1000px",
    margin: "10px 0",
    justifyContent: "space-between",
    alignItems: "center",
  },
  icon: {
    color: "#5EC9BE",
    "&:hover": {
      cursor: "pointer",
    },
  },
  title: {
    width: "100%",
    "& div": {
      width: "100%",
      "& > input": {
        width: "100%",
      },
    },
  },
};

const formatName = (name) => {
  return name
    ? name
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/\//g, "-")
        .replace(/&/g, "and")
        .trim() + ".gif"
    : "";
};

const Exercise = () => {
  const dispatch = useDispatch();
  const classes = useDeviceStyles({ styles });
  const handleError = useHandleError(dispatch);

  const storedExercises = useSelector(
    (state) => state.workouts.setAllExercises
  );

  const [redirect, setRedirect] = React.useState(null);
  const [activeEdits, setActiveEdits] = React.useState(false);
  const [mainExercises, setMainExercises] = React.useState({
    title: "",
    description: "",
    tutorial: "",
    gif: "",
    tags: "",
    equipment: "",
  });
  const [extraExercises, setExtraExercises] = React.useState([]);
  const [isAction, setIsAction] = React.useState(false);

  const { id } = useParams();
  React.useEffect(() => {
    if (id) {
      fetchEntry(id);
    }
  }, [id]);

  React.useEffect(() => {
    if (isAction) {
      fetchEntry(id);
    }
  }, [isAction]);

  const fetchEntry = async (id) => {
    const res = await Ajax.get("exercises", id);
    setMainExercises(res);
    setExtraExercises(res?.extraExercises ?? []);
    setIsAction(false);
  };

  const handleChange = (e) => {
    const val = e.target.value;
    setActiveEdits(true);
    setMainExercises({ ...mainExercises, ...{ [e.target.name]: val } });
  };

  const handleSave = async () => {
    try {
      const dataToSave = { ...mainExercises, extraExercises };
      const res = await Ajax.update("exercises", dataToSave.id, dataToSave);
      setIsAction(true);
      if (storedExercises) {
        dispatch(setAllExercises([...storedExercises, res]));
      } else {
        dispatch(setAllExercises([res]));
      }
      dispatch(setNotification({ msg: "Entry updated", severity: "info" }));
      setActiveEdits(false);
    } catch (e) {
      handleError(e);
    }
  };

  const handleDelete = async () => {
    const confirm = window.confirm(`Delete exercise ${mainExercises.title}?`);
    if (!confirm) return;

    try {
      await Ajax.delete("exercises", mainExercises.id);

      dispatch(setNotification({ msg: "Entry deleted", severity: "info" }));
      setRedirect("/exercises");
    } catch (e) {
      handleError(e);
    }
  };

  const handleImageChange = (type, url, item) => {
    setActiveEdits(true);
    const selectedItem = { ...item, gif: url };
    if (type === "extra") {
      if (item.id) {
        setExtraExercises([
          ...extraExercises.filter((obj) => obj.id !== item.id),
          selectedItem,
        ]);
      } else {
        setExtraExercises([
          ...extraExercises.filter((obj) => obj.vid !== item.vid),
          selectedItem,
        ]);
      }
    } else {
      setMainExercises(selectedItem);
    }
  };

  const handleImageDelete = async (type, item) => {
    try {
      if (!item.gif) return;
      //https://tl-method-exercise-gifs.s3-us-west-2.amazonaws.com/1-2-kneeling-open.gif
      const fileName = item.gif.split("s3-us-west-2.amazonaws.com/")[1];
      const bucket = new URL(item.gif).hostname.split(".")[0];
      // Delete from S3
      await Ajax.deleteFile(fileName, bucket);
      handleImageChange(type, null, item);
    } catch (e) {
      handleError(e);
    }
  };

  const handleAddExercises = () => {
    const newExercises = {
      exerciseId: mainExercises.id,
      vid: new Date().getTime(),
      title: "",
      description: "",
      tutorial: "",
      gif: "",
      tags: "",
    };
    setExtraExercises([...extraExercises, newExercises]);
  };

  const handleExtraChange = (e, item) => {
    const val = e.target.value;
    setActiveEdits(true);
    const selectedItem = { ...item, ...{ [e.target.name]: val } };
    if (item.id) {
      setExtraExercises([
        ...extraExercises.filter((obj) => obj.id !== item.id),
        selectedItem,
      ]);
    } else {
      setExtraExercises([
        ...extraExercises.filter((obj) => obj.vid !== item.vid),
        selectedItem,
      ]);
    }
  };

  const handleDeleteExtraExercises = async (exercise) => {
    if (exercise.id) {
      const confirm = window.confirm(`Delete exercise ${exercise.title}?`);
      if (!confirm) return;
      try {
        await Ajax.delete("exercises/extra", exercise.id);
        dispatch(setNotification({ msg: "Entry deleted", severity: "info" }));
        setIsAction(true);
      } catch (e) {
        handleError(e);
      }
    } else {
      setExtraExercises(
        extraExercises.filter((obj) => obj.vid !== exercise.vid)
      );
    }
  };

  if (!mainExercises) {
    return <Loading height="25px" width="25px" />;
  }

  if (redirect) {
    return <Redirect push to={redirect} />;
  }

  const renderMainForm = (exercisesProps) => {
    const { title, description, tags, tutorial, gif, equipment } =
      exercisesProps;
    return (
      <>
        <div className={classes.row}>
          <TextField
            className={classes.title}
            id="Title"
            placeholder="Title"
            value={title}
            name="title"
            onChange={handleChange}
          />
        </div>

        <div className={classes.row}>
          <TextField
            multiline
            className={classes.title}
            id="Description"
            placeholder="Description"
            value={description}
            name="description"
            onChange={handleChange}
          />
        </div>

        <div className={classes.row}>
          <TextField
            className={classes.title}
            id="Tags"
            placeholder="Tags"
            value={tags}
            name="tags"
            onChange={handleChange}
          />
        </div>

        <div className={classes.row}>
          <TextField
            className={classes.title}
            id="Equipment"
            placeholder="Equipment"
            value={equipment}
            name="equipment"
            onChange={handleChange}
          />
        </div>

        <div className={classes.row}>
          <TextField
            className={classes.title}
            id="Tutorial"
            placeholder="How To Link"
            value={tutorial}
            name="tutorial"
            onChange={handleChange}
          />
        </div>

        <div className={classes.row} style={{ marginBottom: 0, maxWidth: 400 }}>
          <Typography variant="body1">Exercise Gif</Typography>
          {gif ? (
            <Tooltip title="Delete Exercise Gif" placement="top">
              <DeleteIcon
                onClick={() => handleImageDelete("main", exercisesProps)}
                className={classes.icon}
              />
            </Tooltip>
          ) : null}
        </div>

        <div className={classes.row}>
          {gif ? (
            <img
              src={gif}
              className={classes.image}
              alt={mainExercises.title}
            />
          ) : (
            <FilePicker
              bucket="tl-method-exercise-gifs"
              fileName={formatName(title)}
              onUpload={(url) => handleImageChange("main", url, exercisesProps)}
              onError={(e) => handleError(e)}
            />
          )}
        </div>
      </>
    );
  };

  const renderExtraForm = (exercisesProps) => {
    const { title, description, tags, tutorial, gif, vid, equipment } =
      exercisesProps;
    return (
      <>
        <div className={classes.row}>
          <TextField
            className={classes.title}
            id="Title"
            placeholder="Title"
            value={title}
            name="title"
            onChange={(e) => handleExtraChange(e, exercisesProps)}
          />
        </div>

        <div className={classes.row}>
          <TextField
            multiline
            className={classes.title}
            id="Description"
            placeholder="Description"
            value={description}
            name="description"
            onChange={(e) => handleExtraChange(e, exercisesProps)}
          />
        </div>

        <div className={classes.row}>
          <TextField
            className={classes.title}
            id="Tags"
            placeholder="Tags"
            value={tags}
            name="tags"
            onChange={(e) => handleExtraChange(e, exercisesProps)}
          />
        </div>

        <div className={classes.row}>
          <TextField
            className={classes.title}
            id="Equipment"
            placeholder="Equipment"
            value={equipment}
            name="equipment"
            onChange={(e) => handleExtraChange(e, exercisesProps)}
          />
        </div>

        <div className={classes.row}>
          <TextField
            className={classes.title}
            id="Tutorial"
            placeholder="How To Link"
            value={tutorial}
            name="tutorial"
            onChange={(e) => handleExtraChange(e, exercisesProps)}
          />
        </div>

        <div className={classes.row} style={{ marginBottom: 0, maxWidth: 400 }}>
          <Typography variant="body1">Exercise Gif</Typography>
          {gif ? (
            <Tooltip title="Delete Exercise Gif" placement="top">
              <DeleteIcon
                onClick={() => handleImageDelete("extra", exercisesProps)}
                className={classes.icon}
              />
            </Tooltip>
          ) : null}
        </div>

        <div className={classes.row}>
          {gif ? (
            <img
              src={gif}
              className={classes.image}
              alt={mainExercises.title}
            />
          ) : (
            <FilePicker
              bucket="tl-method-exercise-gifs"
              fileName={formatName(title)}
              onUpload={(url) =>
                handleImageChange("extra", url, exercisesProps)
              }
              onError={(e) => handleError(e)}
            />
          )}
        </div>
      </>
    );
  };
  return (
    <main className={classes.main}>
      <div className={classes.row}>
        <Button disabled={!activeEdits} onClick={handleSave}>
          Save
        </Button>
        <Button onClick={handleDelete}>Delete</Button>
      </div>
      {renderMainForm(mainExercises)}
      <div>
        <hr />
        <h2>Added Exercises</h2>
        {extraExercises &&
          extraExercises.length > 0 &&
          extraExercises.map((obj, index) => {
            return (
              <div key={index} style={{ marginTop: "20px" }}>
                <Button
                  onClick={() => {
                    handleDeleteExtraExercises(obj);
                  }}
                >
                  <DeleteIcon
                    style={{ fontSize: "16px", marginRight: "4px" }}
                  />
                  Delete
                </Button>
                {renderExtraForm(obj)}
              </div>
            );
          })}
      </div>
      <div className={classes.row}>
        <Button onClick={handleAddExercises}>Add Exercises</Button>
      </div>
    </main>
  );
};

export default Exercise;
