import React from 'react';
import { useSelector } from 'react-redux';
import { useDeviceStyles } from '../../customHooks';

import { Typography } from '@material-ui/core';

import { styles, mobileStyles } from './styles';

const formatTitle = (page) => {
  switch (page.replace(/\//g, '')) {
    case 'users':
      return 'Users';
    case 'exercises':
      return 'Exercises';
    case 'workouts':
      return 'Workouts';
    case 'challenges':
      return 'Challenges';
    case 'weekly':
      return 'Weekly Workouts';
    default :
      return 'TL Method Admin'
  }
}

const NavHeader = () => {
  const classes = useDeviceStyles({ styles, mobileStyles });
  const currentPage = useSelector(state => state.navigation.currentPage);
  const currentUser = useSelector(state => state.user.currentUser);

  if (!currentUser || !currentUser.email) {
    return (
      <></>
    )
  }

  return (
    <div className={classes.navHeader} >

      <Typography component='h5' variant='h5'>
        {formatTitle(currentPage)}
      </Typography>
      
    </div>
  )
}

export default NavHeader;