import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentPage, toggleSidebar } from '../../redux/actions';
import { Link, useHistory } from 'react-router-dom';
import { useDeviceStyles } from '../../customHooks';

import { Drawer, Typography } from '@material-ui/core';

import PersonIcon from '@material-ui/icons/Person';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import EmojiFlagsIcon from '@material-ui/icons/EmojiFlags';
import TodayIcon from '@material-ui/icons/Today';

import { styles, mobileStyles, tabletStyles } from './styles';

const BUSINESS_TITLE = 'TL Method';
const LOGO_SRC = 'https://cdn.shopify.com/s/files/1/0055/1292/1141/files/Screen_Shot_2018-05-07_at_10.12.12_AM.png';
const TOKEN_PROP = 'tl-method-token';

const SideBar = (props) => {
  // Component Styles
  const classes = useDeviceStyles({ styles, mobileStyles, tabletStyles });
  //////////////////

  // Redux Store
  const dispatch = useDispatch();
  const currentPage = useSelector(state => state.navigation.currentPage);
  const sidebarIsOpen = useSelector(state => state.navigation.sidebarIsOpen);
  //////////////

  const history = useHistory();

  const handleNavigation = (loc) => {
    dispatch(setCurrentPage(loc));
  }

  const handleLogout = () => {
    localStorage.removeItem(TOKEN_PROP);
    dispatch(toggleSidebar(false));
    history.push('/');
  }

  const SideBarLink = (props) => {
    const { Icon, pageName, linkName } = props;

    return (
      <div className={classes.navElement} style={currentPage === pageName ? { color: '#C08DE2' } : {}}>
        <Icon />
        <Link to={pageName} style={{width: '100%'}} onClick={() =>  handleNavigation(pageName)}>
          <Typography component="h3" variant="h5" className={classes.navLink} style={currentPage === pageName ? { color: '#C08DE2' } : {}}>
            {linkName}
          </Typography>
        </Link>
      </div>
    )
  }

  return (
    <Drawer anchor='left' open={sidebarIsOpen} variant="persistent">
      <div className={classes.sidebar}>

        <img className={classes.logo} src={LOGO_SRC} alt={BUSINESS_TITLE} style={{ height:150, width: 150 }} />

        <div className={classes.navigation}>
          <SideBarLink Icon={PersonIcon} pageName='/users' linkName='Users' />

          <SideBarLink Icon={DirectionsRunIcon} pageName='/exercises' linkName='Exercises' />

          <SideBarLink Icon={FitnessCenterIcon} pageName='/workouts' linkName='Workouts' />

          <SideBarLink Icon={EmojiFlagsIcon} pageName='/challenges' linkName='Challenges' />

          <SideBarLink Icon={TodayIcon} pageName='/weekly' linkName='Weekly Workouts' />

          <div onClick={handleLogout} className={classes.navElement}>
            <Typography component="h3" variant="h5" className={classes.navLink}>
              Logout
            </Typography>
          </div>

        </div>
        
      </div>
    </Drawer>
  )
}

export default SideBar;