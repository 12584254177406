import React from 'react';
import { useDeviceStyles } from '../../customHooks';

import { Tooltip } from '@material-ui/core';

import FileCopyIcon from '@material-ui/icons/FileCopy';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import QueueIcon from '@material-ui/icons/Queue';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUp from '@material-ui/icons/ArrowDropUp';
import ArrowDown from '@material-ui/icons/ArrowDropDown';

const styles = {
  icon: {
    color: '#5EC9BE',
    '&:hover': {
      cursor: 'pointer',
    }
  }
}

const GroupButtons = (props) => {
  const { handleCopyAll, handlePaste, handlePasteAll, handleSave, handleDelete, handleMove } = props;
  const classes = useDeviceStyles({ styles });

  return (
    <div style={{ display: 'flex', width: 300, justifyContent: 'space-between'}}>
      <Tooltip title='Move Up' placement='top'>
        <ArrowUp onClick={() => handleMove(-1)} className={classes.icon} />
      </Tooltip>

      <Tooltip title='Move Down' placement='top'>
        <ArrowDown onClick={() => handleMove(1)} className={classes.icon} />
      </Tooltip>

      <Tooltip title='Paste Single Exercise' placement='top'>
        <AddCircleIcon onClick={handlePaste} className={classes.icon} />
      </Tooltip>

      <Tooltip title='Copy All Exercises' placement='top'>
        <FileCopyIcon onClick={handleCopyAll} className={classes.icon} />
      </Tooltip>

      <Tooltip title='Paste All Exercises' placement='top'>
        <QueueIcon onClick={handlePasteAll} className={classes.icon} />
      </Tooltip>

      {handleSave 
        ? 
          <Tooltip title='Save Changes' placement='top'>
            <SaveIcon onClick={handleSave} className={classes.icon} />
          </Tooltip>
        : 
          null
      }

      <Tooltip title='Delete Set' placement='top'>
        <DeleteIcon onClick={handleDelete} className={classes.icon} />
      </Tooltip>
    </div>
  )
}

export default GroupButtons;