import React from 'react';
import { Redirect } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

export const WorkoutPreview = ({ workout, onDelete, className }) => {
  const [redirect, setRedirect] = React.useState(null);

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete();
  }

  if (redirect) {
    return (
      <Redirect to={redirect} />
    )
  }

  return (
    <div style={{ width: '50%', display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
      <Typography>{workout.date}</Typography>
      <Typography 
        className={className}
        onClick={() => setRedirect(`/workouts/${workout.id}`)}>
        {workout.type}
      </Typography>

      <div style={{ width: '25%', display: 'flex', justifyContent: 'flex-end' }}>
        <DeleteIcon onClick={handleDelete} />
      </div>
    </div>
  )
};