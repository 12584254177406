import React from 'react';
import Ajax from '../../Ajax';

import Entries from '../../Components/Entries';

const Exercises = () => {
  const headCells = ['ID', 'Title', 'Start Date', 'End Date', 'Tags'];
  const rowProps = ['id', 'title', 'startDate', 'endDate', 'tags'];

  const defaultValues = {
    title: '',
    tags: '',
  }

  return (
    <Entries 
      headCells={headCells}
      rowProps={rowProps}
      searchFunction={(query) => Ajax.search('challenges', query)} 
      createFunction={() => Ajax.create('challenges', defaultValues)}
      requestData={(start, perPage) => Ajax.getAll('challenges', start, perPage)} 
      requestCount={() => Ajax.count('challenges')} 
      redirectLink='challenges'
    />
  )
}

export default Exercises;