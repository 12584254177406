import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { setNotification } from '../../redux/actions';
import { useDeviceStyles, useHandleError } from '../../customHooks';
import Ajax from '../../Ajax';

import { Typography, Button, TextField } from '@material-ui/core';

import Loading from '../../Components/Helpers/Loading';
import { WorkoutSearch } from '../Challenges/WorkoutSearch';
import { WorkoutPreview } from '../Challenges/WorkoutPreview';


const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    width: '100%',
    maxWidth: '1000px',
    margin: '10px 0',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  workoutSearch: {
    width: '100%'
  },
  title: {
    width: '100%',
    '& div': {
      width: '100%',
      '& > input': {
        width: '100%'
      }
    }
  },
}

const Weekly = () => {
  const dispatch = useDispatch();
  const classes = useDeviceStyles({ styles });

  const handleError = useHandleError(dispatch);

  const [redirect, setRedirect] = React.useState(null);
  const [entry, setEntry] = React.useState(null);
  const [activeEdits, setActiveEdits] = React.useState(false);
  
  const [updates, setUpdates] = React.useState({
    message: '',
    ids: [],
  });

  React.useEffect(() => {
    const fetchEntry = async () => {
      const res = await Ajax.get('weekly');
      setEntry(res);
      setUpdates({
        message: res.message,
        ids: res?.workouts.map(w => w.id),
      });
    }

    fetchEntry();
  }, []);

  const handleChange = (e, isCheckbox) => {
    const val = isCheckbox ? e.target.checked : e.target.value;

    setActiveEdits(true);
    setUpdates({...updates, ...{ [e.target.name]: val }});
  }

  const handleSave = async () => {
    try {
      if (entry && entry.id) {
        await Ajax.update('weekly', entry.id, updates);
      } else {
        await Ajax.create('weekly', updates);
      }

      const update = await Ajax.get('weekly');

      setEntry(update);

      setUpdates({
        message: update.message,
        ids: update?.workouts.map(w => w.id),
      });

      dispatch(setNotification({ msg: 'Entry updated', severity: 'info' }));

      setActiveEdits(false);
    } catch (e) {
      handleError(e);
    }
  }

  const handleDelete = async () => {
    const confirm = window.confirm(`Delete weekly workout?`);
    if (!confirm) return;

    try {
      await Ajax.delete('weekly', entry.id);

      dispatch(setNotification({ msg: 'Entry deleted', severity: 'info' }));
      setRedirect('/');
    } catch (e) {
      handleError(e);
    }
  }

  const handleAddWorkout = async (workoutId, index) => {
    const updatedIds = [
      ...updates.ids.slice(0, index),
      workoutId,
      ...updates.ids.slice(index + 1)
    ];

    setUpdates((prevState) => ({
      ...prevState,
      ids: updatedIds
    }));
    setActiveEdits(true);
  }

  const handleDeleteWorkout = async (index) => {
    const updatedIds = [
      ...updates.ids.slice(0, index),
      null,
      ...updates.ids.slice(index + 1)
    ].filter(w => Boolean(w));

    setUpdates((prevState) => ({
      ...prevState,
      ids: updatedIds
    }));

    setActiveEdits(true);
  }

  if (!entry) {
    return (
      <Loading height='25px' width='25px' />
    )
  }

  if (redirect) {
    return (
      <Redirect push to={redirect} />
    )
  }

  return (
    <main className={classes.main}>

      <div className={classes.row}>
        <Button disabled={!activeEdits} onClick={handleSave}>Save</Button>
        <Button onClick={handleDelete}>Delete</Button>
      </div>

      <div className={classes.row}>
        <TextField className={classes.title} multiline id='Message' placeholder='Message' value={updates.message} name='message' onChange={handleChange} />
      </div>
    
      {days.map((day, i) => (
        <div>
          <Typography variant='h6'>{days[i]}</Typography>
          <WorkoutSearch 
            className={classes.workoutSearch}
            onChange={(id) => handleAddWorkout(id, i)}
          />

          {entry?.workouts && updates?.ids && entry.workouts.find(w => w.id === updates.ids[i]) &&
            <WorkoutPreview className={classes.workoutPreview} workout={entry.workouts.find(w => w.id === updates.ids[i])} onDelete={() => handleDeleteWorkout(i)}/>
          }
        </div>
      ))}
    </main>
  )
}

export default Weekly;