import React from 'react';
import { useDeviceStyles } from '../../customHooks';

import { TextField, MenuItem } from '@material-ui/core';

const ExerciseInput = ({ name, type, label, value, onChange, options, width }) => {
  const classes = useDeviceStyles({
    styles: {
      input: {
        margin: '0 10px',
        width,
        minWidth: 0,
        '& > div': {
          width
        }
      }
    }
  });

  const handleChange = (e) => {
    onChange(name, e.target.value);
  }

  if (type === 'select') {
    return (
      <TextField
        className={classes.input}
        select
        label={label}
        value={value}
        onChange={handleChange}
      >
        {options.map((option) => (
          <MenuItem key={option.prop} value={option.prop}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    )
  }

  return (
    <TextField onChange={handleChange} label={label} type={type} className={classes.input} value={value} />
  )
}

export default ExerciseInput;