import { createMuiTheme } from '@material-ui/core';

const primary = '#2B215D';
const secondary = '#C08DE2';
const tertiary = '#DADB22';
const default_ = '#000';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
  },
});

// Component Props
theme.props = {
  MuiPaper: {
    elevation: 4,
  },
  MuiButton: {
    variant: 'contained',
    color: 'primary'
  },
  MuiRadio: {
    color: 'primary'
  },
  MuiSwitch: {
    color: 'primary'
  },
  MuiAlert: {
    variant: 'filled'
  },
  MuiTextField: {
    color: 'secondary'
  },
  MuiTabs: {
    indicatorColor: 'primary'
  },
  MuiPagination: {
    color: 'secondary'
  },
  MuiCheckbox: {
    color: 'primary'
  }
}

// Component CSS Rules
theme.overrides = {
  a: {
    root: {
      textDecoration: 'none'
    }
  },

  // Typography
  MuiTypography: {
    root: {
      fontFamily: 'Montserrat Regular',
    },
    body2: {
      fontFamily: 'Montserrat Regular',
      fontSize: 16,
      color: default_,
    },
    body1: {
      fontFamily: 'Montserrat Regular',
      fontSize: 16,
      color: default_,
    },
    h6: {
      fontFamily: 'GibsonRegular',
      color: default_,
    },
    h5: {
      fontFamily: 'GibsonRegular',
      color: default_,
    },
    h4: {
      fontFamily: 'GibsonRegular',
      color: default_,
    },
    h3: {
      fontFamily: 'Oswald Medium',
      fontSize: 20,      
      color: default_,
    },
    h2: {
      fontFamily: 'Montserrat Regular',
      fontSize: 36,
      color: default_,
    },
    h1: {
      fontFamily: 'Oswald Medium',
      fontSize: 28,
      color: default_,
    },
  },

  // Paper
  MuiPaper: {
    root: {
      // backgroundColor: cyan[400]
    }
  },

  // Button
  MuiButton: {
    root: {
      borderRadius: '10px',
      textTransform: 'none',
      margin: '10px 5px',
      minWidth: '145px',
      '&&:hover': {
        backgroundColor: primary,
        color: '#fff'
      }
    },
    containedPrimary: {
      backgroundColor: primary,
      color: '#fff',

    },
    containedSecondary: {
      // fontWeight: '700'
    }
  },

  // Form Control
  MuiFormControlLabel: {
    label: {
      color: '#000'
    }
  },

  MuiTextField: {
    root: {
      minWidth: '250px'
    }
  },

  // Alert
  MuiAlert: {
    filledInfo: {
      backgroundColor: secondary,
      color: '#fff'
    },
    filledSuccess: {
      backgroundColor: tertiary,
      color: '#fff'
    }
  }
}

export default theme;