import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAllWorkouts } from '../../redux/actions';
import { Redirect, useParams } from 'react-router-dom';
import { addDays, startOfDay, isAfter } from 'date-fns';

import { setNotification } from '../../redux/actions';
import { useDeviceStyles, useHandleError } from '../../customHooks';
import Ajax from '../../Ajax';

import { Typography, Button, TextField, FormControlLabel, Switch } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import FilePicker from '../../Components/Helpers/FilePicker';

import Loading from '../../Components/Helpers/Loading';
import DatePicker from '../../Components/Helpers/DatePicker';

import { WorkoutPreview } from './WorkoutPreview';
import { WorkoutSearch } from './WorkoutSearch';

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    width: '100%',
    maxWidth: '1000px',
    margin: '10px 0',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  number: {
    width: 15
  },
  title: {
    width: '100%',
    '& div': {
      width: '100%',
      '& > input': {
        width: '100%'
      }
    }
  },
  image: {
    maxWidth: 800,
  },
  workoutSearch: {
    width: '100%'
  },
  workoutPreview: {
    color: 'blue',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}

const fillInWeeks = (data, workouts) => {
  let weeks = [];

  data.forEach(week => {
    let innerWeek = [];
    week.forEach(workoutId => {
      const workout = workouts.find(w => w.id === workoutId);
      if (workout) innerWeek.push(workout);
    });

    if (innerWeek.length) weeks.push(innerWeek);
  });

  return weeks;
}

const formatName = (name) => {
  return name.toLowerCase().replace(/ /g, '-').replace(/\//g, '-').replace(/&/g, 'and').trim() + '.png';
}

const Challenge = () => {
  const dispatch = useDispatch();
  const classes = useDeviceStyles({ styles });

  const handleError = useHandleError(dispatch);

  const [redirect, setRedirect] = React.useState(null);
  const [entry, setEntry] = React.useState(null);
  const [activeEdits, setActiveEdits] = React.useState(false);
  const [workoutsPerWeek, setPerWeek] = React.useState(6);

  const storedWorkouts = useSelector(state => state.workouts.allWorkouts);

  const [updates, setUpdates] = React.useState({
    startDate: '',
    isPublished: false,
    endDate: '',
    tags: '',
    title: '',
    image: '',
    data: [],
    weeks: [],
  });

  const {id} = useParams();

  React.useEffect(() => {
    const fetchEntry = async (id) => {
      if (!storedWorkouts?.length) {
        const allWorkouts = await Ajax.getAll('workouts');
        dispatch(setAllWorkouts(allWorkouts));
      }

      const res = await Ajax.get('challenges', id);
      setEntry(res);
      setUpdates({
        ...res,
        startDate: addDays(new Date(res.startDate), 1),
        endDate: addDays(new Date(res.endDate), 1),
        weeks: fillInWeeks(res.data, res.workouts),
      });

    }

    if (!entry && id) {
      fetchEntry(id);
    }
  }, [dispatch, entry, storedWorkouts, id]);

  const handleChange = (e, isCheckbox) => {
    if (e.target.name === 'isPublished' && e.target.checked) {
      const confirm = window.confirm('Heads up! Toggling the published field will make this challenge live.');
      if (!confirm) return;
    }

    const val = isCheckbox ? e.target.checked : e.target.value;

    setActiveEdits(true);
    setUpdates({...updates, ...{ [e.target.name]: val }});
  }

  const handleImageChange = (url) => {
    setActiveEdits(true);
    setUpdates({...updates, ...{ image: url }});
  }

  const handleImageDelete = async () => {
    try {
      if (!updates.image) return;

      //https://tl-method-challenge-images.s3-us-west-2.amazonaws.com/1-2-kneeling-open.gif
      const fileName = updates.image.split('s3-us-west-2.amazonaws.com/')[1];
      const bucket = new URL(updates.image).hostname.split('.')[0];

      // Delete from S3
      await Ajax.deleteFile(fileName, bucket)

      handleImageChange(null);
    } catch (e) {
      handleError(e);
    }
  }

  const handleDateChange = (date, target) => {
    setUpdates(prevState => {
      return {
        ...prevState,
        [target]: startOfDay(date)
      }
    });

    setActiveEdits(true);
  }

  const handleAddWeek = () => {
    setUpdates(prevState => {
      return {
        ...prevState,
        weeks: [...prevState.weeks, []],
      }
    });
    setActiveEdits(true);
  }

  const handleDeleteWeek = (index) => {
    let updatedData = updates.data;
    updatedData = [...updatedData.slice(0, index), ...updatedData.slice(index + 1)];

    setUpdates(prevState => {
      return {
        ...prevState,
        data: updatedData,
        weeks: fillInWeeks(updatedData, storedWorkouts)
      }
    });
    setActiveEdits(true);
  }

  const handleGenerate = async () => {
    try {
      // Get all workouts between start and end date
      const start = startOfDay(new Date(updates.startDate));
      const end = startOfDay(new Date(updates.endDate));

      if (isAfter(start, end)) {
        throw Error('Start date is after end date');
      }

      const workouts = await Ajax.getWorkoutsBetween(start, end);

      let data = [];

      for (let i = 0; i < workouts.length / workoutsPerWeek; i++) {
        data.push([]);
      }

      data.forEach(week => {
        for (let i = 0; i < workoutsPerWeek; i++) {
          week.push(workouts.shift());
        }
      });

      const dataWithIds = data
        .map(w => w.map(wo => wo?.id))
        .filter(w => Boolean(w));

      setUpdates(prevState => {
        return {
          ...prevState,
          data: dataWithIds,
          weeks: fillInWeeks(dataWithIds, storedWorkouts)
        }
      });
      setActiveEdits(true);

    } catch (e) {
      handleError(e);
    }
  }

  const handleAddWorkout = (workoutId, weekIndex) => {
    const updatedData = updates.data.map((week, i) => {
      if (i !== weekIndex) return week;

      return [...week, workoutId];
    });

    setUpdates(prevState => {
      return {
        ...prevState,
        data: updatedData,
        weeks: fillInWeeks(updatedData, storedWorkouts)
      }
    });
    setActiveEdits(true);
  }

  const handleWorkoutDelete = (row, column) => {
    let updatedData = updates.data;
    updatedData[row] = [...updatedData[row].slice(0, column), ...updatedData[row].slice(column + 1)];

    setUpdates(prevState => {
      return {
        ...prevState,
        data: updatedData,
        weeks: fillInWeeks(updatedData, storedWorkouts)
      }
    });

    setActiveEdits(true);
  }

  const handleSave = async () => {
    try {

      updates.data = updates.weeks.map(w => w.map(wo => wo.id));

      const res = await Ajax.update('challenges', updates.id, updates);

      setEntry(res);

      dispatch(setNotification({ msg: 'Entry updated', severity: 'info' }));

      setActiveEdits(false);
    } catch (e) {
      handleError(e);
    }
  }

  const handleDelete = async () => {
    const confirm = window.confirm(`Delete challenge ${entry.date}?`);
    if (!confirm) return;

    try {
      await Ajax.delete('challenges', entry.id);

      dispatch(setNotification({ msg: 'Entry deleted', severity: 'info' }));
      setRedirect('/challenges');
    } catch (e) {
      handleError(e);
    }
  }

  if (!entry) {
    return (
      <Loading height='25px' width='25px' />
    )
  }

  if (redirect) {
    return (
      <Redirect push to={redirect} />
    )
  }

  return (
    <main className={classes.main}>

      <div className={classes.row}>
        <Button disabled={!activeEdits} onClick={handleSave}>Save</Button>
        <Button onClick={handleDelete}>Delete</Button>
      </div>

      <div className={classes.row}>
        <DatePicker selectedDate={new Date(updates.startDate) || new Date()} onChange={(date) => handleDateChange(date, 'startDate')} />
        <DatePicker selectedDate={new Date(updates.endDate) || new Date()} onChange={(date) => handleDateChange(date, 'endDate')} />

        <FormControlLabel
          control={ <Switch checked={updates.isPublished} onChange={(e) => handleChange(e, true)} name='isPublished' />}
          label='Published'
        />
      </div>

      <div className={classes.row}>
        <Button onClick={handleGenerate}>Generate Challenge from Dates</Button>
        <TextField
          className={classes.number}
          type='number'
          id='Workouts per week'
          label='Workouts per Week'
          value={workoutsPerWeek}
          onChange={(e) => setPerWeek(e.target.value)}
        />
      </div>

      <div className={classes.row}>
        <TextField className={classes.title} multiline id='Title' placeholder='Title' value={updates.title} name='title' onChange={handleChange} />
      </div>

      <div className={classes.row}>
        <TextField className={classes.title} id='Tags' placeholder='Tags' value={updates.tags} name='tags' onChange={handleChange} />
      </div>

      <div className={classes.row} style={{ marginBottom: 0, maxWidth: 400 }}>
        <Typography variant='body1'>Hero Image</Typography>
        { updates.image ? <DeleteIcon onClick={handleImageDelete} className={classes.icon} /> : null }
      </div>

      <div className={classes.row}>
        { updates.image
          ? <img src={updates.image} className={classes.image} alt={entry.title} />

          : <FilePicker bucket='tl-method-challenge-images' fileName={formatName(updates.title)} onUpload={(url) => handleImageChange(url)} onError={(e) => handleError(e)}/>
        }
      </div>

      <div className={classes.row}>
        <Button onClick={handleAddWeek}>Add Week</Button>
      </div>

      { updates.weeks.map((week, i) => (
        <div>
          <div style={{ width: '10%', display: 'flex', justifyContent: 'space-between', margin: '10px 0 10px 0', borderBottom: '1px solid grey' }}>
            <Typography>{`Week ${i + 1}`}</Typography>
            <DeleteIcon onClick={() => handleDeleteWeek(i)} />
          </div>

          <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '10px 0 10px 0', borderBottom: '1px solid grey' }}>
            <WorkoutSearch
              className={classes.workoutSearch}
              onChange={(id) => handleAddWorkout(id, i)}
            />
          </div>

          { week.map((workout, j) => (
            <WorkoutPreview className={classes.workoutPreview} workout={workout} onDelete={() => handleWorkoutDelete(i, j)}/>
          ))}

        </div>
      ))}

    </main>
  )
}

export default Challenge;
