import React from 'react';
import Ajax from '../../Ajax';

import Entries from '../../Components/Entries';

const Exercises = () => {
  const headCells = ['ID', 'Gif', 'Title', 'Description', 'Tags'];
  const rowProps = ['id', 'gif', 'title', 'description', 'tags'];

  const defaultValues = {
    title: '',
    description: '',
    gif: '',
    tags: '',
  }

  return (
    <Entries 
      headCells={headCells}
      rowProps={rowProps}
      searchFunction={(query) => Ajax.search('exercises', query)} 
      createFunction={() => Ajax.create('exercises', defaultValues)}
      requestData={(start, perPage) => Ajax.getAll('exercises', start, perPage)} 
      requestCount={() => Ajax.count('exercises')} 
      redirectLink='exercises'
    />
  )

}

export default Exercises;