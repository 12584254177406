import React from 'react';
import { Redirect } from 'react-router-dom';

import { Button } from '@material-ui/core';

const Create = (props) => {
  const { createFunction, redirectLink, defaultValues } = props;

  const [redirect, setRedirect] = React.useState(null);

  const handleCreate = async () => {
    const res = await createFunction(defaultValues);
    const { id } = res;
    setRedirect(`${redirectLink}/${id}`);
  }

  if (redirect) {
    return (
      <Redirect push to={redirect} />
    )
  }

  return (
    <div>
      <Button onClick={handleCreate}>New</Button>

    </div>
  )
}

export default Create;