import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';

const Loading = (props) => {

  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    minHeight: '500px',
    minWidth: '500px'
  }

  return (
    props.type === 'linear' ? 
      <LinearProgress color='primary' />
    :
      <div style={style}>
        <CircularProgress style={{ height: props.height, width: props.width, color: props.color || '#FF4677' }} />
      </div>
  )
}

export default Loading;