import React from "react";
import { useDeviceStyles } from "../../customHooks";
import { useDropzone } from "react-dropzone";
import Ajax from "../../Ajax";

import { Typography, Button } from "@material-ui/core";
import PublishIcon from "@material-ui/icons/Publish";

const styles = {
  filePicker: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
};

const FilePicker = ({ onUpload, onError, bucket, fileName }) => {
  const classes = useDeviceStyles({ styles });
  const [file, setFile] = React.useState(null);

  const readFile = async (files) => {
    if (!("TextDecoder" in window))
      alert(
        "This browser does not support decoding txt files. Please try the most recent version of Chrome, Firefox or Safari."
      );

    const file = files[0];
    setFile(file);
  };

  const onDrop = React.useCallback((files) => {
    readFile(files);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
  });

  const handleUpload = async () => {
    try {
      const res = await Ajax.uploadFile(file, bucket, fileName);
      onUpload(res);
    } catch (e) {
      onError(e);
    }
  };

  return (
    <div className={classes.filePicker}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {file ? (
          <Typography variant="h6">File ready to upload</Typography>
        ) : isDragActive ? (
          <Typography variant="h6">Drop file here</Typography>
        ) : (
          <div className={classes.filePicker}>
            <Typography variant="h6">
              Drag file here or click to select
            </Typography>
            <PublishIcon fontSize="large" />
          </div>
        )}
      </div>
      <Button disabled={!Boolean(file)} onClick={handleUpload}>
        {file ? "Upload" : "Select a File"}
      </Button>
    </div>
  );
};

export default FilePicker;
