import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';

import { setNotification } from '../../redux/actions';
import { useDeviceStyles } from '../../customHooks';
import Ajax from '../../Ajax';

import { Typography, Button, Switch, FormControlLabel, Tooltip } from '@material-ui/core';

import Loading from '../../Components/Helpers/Loading';

const MEMBERFUL_REDIRECT_LINK = '';

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    width: '100%',
    maxWidth: '1000px',
    margin: '10px 0',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

const User = () => {
  const dispatch = useDispatch();
  const classes = useDeviceStyles({ styles });

  const [redirect, setRedirect] = React.useState(null);
  const [entry, setEntry] = React.useState(null);
  const [activeEdits, setActiveEdits] = React.useState(false);
  const [updates, setUpdates] = React.useState({
    isAdmin: false,
    isActive: false,
    primaryDiet: '',
    isGlutenFree: false,
    isDairyFree: false,
    unitPreference: ''
  });

  const {id} = useParams();

  React.useEffect(() => {
    const fetchEntry = async (id) => {
      const res = await Ajax.get('users', id);
      setEntry(res);
      setUpdates({...res});
    }

    if (!entry && id && id[0]) {
      fetchEntry(id[0]);
    }
  }, [entry, id]);

  const handleChange = (e) => {
    const val = e.target.value ? e.target.value : e.target.checked;
    setActiveEdits(true);
    setUpdates({...updates, ...{ [e.target.name]: val }});
  }

  const handleSave = async () => {
    try {
      const res = await Ajax.update('users', updates.id, updates);

      setEntry(res);
      setUpdates({...res});

      dispatch(setNotification({ msg: 'Entry updated', severity: 'info' }));

      setActiveEdits(false);
    } catch (e) {
      dispatch(setNotification({ msg: e.message, severity: 'error' }));
    }
  }

  const handleDelete = async () => {
    const confirm = window.confirm(`Delete user ${entry.email}?`);
    if (!confirm) return;

    try {
      await Ajax.delete('users', entry.id);

      dispatch(setNotification({ msg: 'Entry deleted', severity: 'info' }));
      setRedirect('/users');
    } catch (e) {
      dispatch(setNotification({ msg: e.message, severity: 'error' }));
    }
  }

  const memberfulLink = (id) => {
    return (
      <a
        href={`${MEMBERFUL_REDIRECT_LINK}/${id}`}
        target='_blank' rel='noopener noreferrer'
      >{id}</a>
    )
  }

  if (!entry) {
    return (
      <Loading height='25px' width='25px' />
    )
  }

  if (redirect) {
    return (
      <Redirect push to={redirect} />
    )
  }

  return (
    <main className={classes.main}>

      <div className={classes.row}>
        <Button disabled={!activeEdits} onClick={handleSave}>Save</Button>
        <Button onClick={handleDelete}>Delete</Button>
      </div>

      <div className={classes.row}>
        <Typography>{entry.email}</Typography>
        <Typography>Memberful ID: {memberfulLink(entry.memberfulId)}</Typography>
      </div>

      <div className={classes.row}>
        <FormControlLabel
          control={ <Switch checked={updates.isAdmin} onChange={handleChange} name='isAdmin' />}
          label='Admin'
        />

        <Tooltip placement='top' title='Change in Memberful'>
          <FormControlLabel
            control={ <Switch checked={updates.isActive} disabled /> }
            label='Active Membership'
          />
        </Tooltip>
      </div>

    </main>
  )
}

export default User;
