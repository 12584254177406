import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './theme';

import ScrollToTop from './Components/Helpers/ScrollToTop';
import NotFound from './Components/Helpers/NotFound';
import Notify from './Components/Helpers/Notify';
import ProtectedRoute from './Components/Helpers/ProtectedRoute'

import NavHeader from './Components/NavHeader';
import SideBar from './Components/SideBar'

import SignIn from './Views/SignIn';
import Users from './Views/Users';
import User from './Views/Users/User';
import Exercises from './Views/Exercises';
import Exercise from './Views/Exercises/Exercise';
import Workouts from './Views/Workouts';
import Workout from './Views/Workouts/Workout';
import Challenges from './Views/Challenges';
import Challenge from './Views/Challenges/Challenge';
import Weekly from './Views/Weekly';

function App({ store }) {
  return (
    <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <Router>
            <ScrollToTop>

              <NavHeader />
              <SideBar />

              <Switch>
                <Route exact path='/' component={SignIn} />

                <ProtectedRoute exact path='/users' component={Users} />
                <ProtectedRoute exact path='/users/:id' component={User} />


                <ProtectedRoute exact path='/exercises' component={Exercises} />
                <ProtectedRoute exact path='/exercises/:id' component={Exercise} />

                <ProtectedRoute exact path='/workouts' component={Workouts} />
                <ProtectedRoute exact path='/workouts/:id' component={Workout} />

                <ProtectedRoute exact path='/challenges' component={Challenges} />
                <ProtectedRoute exact path='/challenges/:id' component={Challenge} />

                <ProtectedRoute exact path='/weekly' component={Weekly} />

                <Route component={NotFound} />
              </Switch>
            </ScrollToTop>
          </Router>

          <Notify />
        </Provider>
    </MuiThemeProvider>
  );
}

export default App;
