import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setAllWorkouts } from '../../redux/actions';
import Ajax from '../../Ajax';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

export const WorkoutSearch = ({ label, currentSelection, onChange, className }) => {

  const storedWorkouts = useSelector(state => state.workouts.allWorkouts);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const fetchAllExercises = async () => {
      const res = await Ajax.getAll('workouts');
      dispatch(setAllWorkouts(res));
    }

    if (!storedWorkouts) fetchAllExercises();

  }, [dispatch, storedWorkouts])

  const handleChange = (e, val) => {
    const selection = storedWorkouts.find(ing => ing.date === val);

    if (selection) {
      onChange(selection.id);
    }
  }

  return (
    <Autocomplete
      freeSolo
      className={className}
      onChange={handleChange}
      value={currentSelection?.date || ''}
      options={storedWorkouts?.map(i => i.date)}
      renderInput={(params) => (
        <TextField {...params} label={label} margin="normal" variant="outlined" placeholder='Search for workouts by date' />
      )}
    />
  )
}
