import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { parse, startOfDay } from 'date-fns';

import { setNotification } from '../../redux/actions';
import { useDeviceStyles, useHandleError } from '../../customHooks';
import Ajax from '../../Ajax';

import { Typography, Button, TextField, FormControlLabel, Switch } from '@material-ui/core';

import Loading from '../../Components/Helpers/Loading';
import DatePicker from '../../Components/Helpers/DatePicker';
import Group from '../../Components/Group';

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    width: '100%',
    maxWidth: '1000px',
    margin: '10px 0',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    width: '100%',
    '& div': {
      width: '100%',
      '& > input': {
        width: '100%'
      }
    }
  },
}

const Workout = () => {
  const dispatch = useDispatch();
  const classes = useDeviceStyles({ styles });

  const handleError = useHandleError(dispatch);

  const [redirect, setRedirect] = React.useState(null);
  const [entry, setEntry] = React.useState(null);
  const [activeEdits, setActiveEdits] = React.useState(false);
  const [trigger, setTrigger] = React.useState(false);

  const [groups, setGroups] = React.useState([]);
  const [updates, setUpdates] = React.useState({
    date: '',
    isPublished: false,
    description: '',
    equipment: '',
    tags: '',
    minLength: 15,
    maxLength: 30,
    extraData: {}
  });

  const [loading, setLoading] = React.useState(false);

  const fetchEntry = React.useCallback(async (id) => {
    const res = await Ajax.get('workouts', id);
    setEntry(res);
    setGroups(res.groups);
    setUpdates({
      ...res,
      date: parse(res.date, 'yyyy-MM-dd', new Date()),
      extraData: res.extraData || {},
    });
  }, []);

  const {id} = useParams();

  React.useEffect(() => {
    if (id) {
      fetchEntry(id);
    }
  }, [fetchEntry, id]);

  const handleChange = React.useCallback((e, isCheckbox, isExtraData) => {
    if (e.target.name === 'isPublished' && e.target.checked) {
      const confirm = window.confirm('Heads up! Toggling the published field will make this workout live.');
      if (!confirm) return;
    }

    if (isExtraData) {
      const updatedData = {
        ...updates.extraData,
        [e.target.name]: e.target.value,
      }

      setActiveEdits(true);
      setUpdates({ ...updates, extraData: updatedData });
      return;
    }

    const val = isCheckbox ? e.target.checked : e.target.value;

    setActiveEdits(true);
    setUpdates({...updates, ...{ [e.target.name]: val }});
  }, [updates]);

  const handleDateChange = React.useCallback((date) => {
    setUpdates(prevState => {
      return {
        ...prevState,
        date: startOfDay(date)
      }
    })
    setActiveEdits(true);
  }, []);

  const handleSave = React.useCallback(async () => {
    try {
      const res = await Ajax.update('workouts', updates.id, updates);

      setEntry(res);
      setTrigger(true);

      dispatch(setNotification({ msg: 'Entry updated', severity: 'info' }));

      setActiveEdits(false);
    } catch (e) {
      handleError(e);
    }
  }, [dispatch, handleError, updates]);

  const handleDelete = React.useCallback(async () => {
    const confirm = window.confirm(`Delete workout ${entry.date}?`);
    if (!confirm) return;

    try {
      await Ajax.delete('workouts', entry.id);

      dispatch(setNotification({ msg: 'Entry deleted', severity: 'info' }));
      setRedirect('/workouts');
    } catch (e) {
      handleError(e);
    }
  }, [dispatch, entry, handleError]);

  const handleAddGroup = React.useCallback(async () => {
    try {
      setTrigger(true);
      await Ajax.create('groups', { workoutId: entry.id, data: {
        extraData: { index: groups.length + 1 }
      } });

      const res = await Ajax.get('workouts', entry.id);
      setGroups(res.groups);

    } catch (e) {
      handleError(e);
    }
  }, [entry, groups.length, handleError]);

  const handleRemoveGroup = React.useCallback(async (groupId) => {
    try {

      await Ajax.delete('groups', groupId);
      const res = await Ajax.get('workouts', entry.id);

      let sorted = [];

      // Update the index of groups
      let i = 1;
      for (const group of res.groups) {
        const update = {...group};
        if (!update.extraData) update.extraData = {};
        update.extraData.index = i;
        const groupRes = await Ajax.update('groups', group.id, update);
        sorted.push(groupRes);
        i++;
      }

      setGroups(sorted);

    } catch (e) {
      handleError(e);
    }
  }, [entry, handleError]);

  const handleReorder = React.useCallback(async (index, newIndex) => {
    if (loading) return;
    setLoading(true);
    const original = {...groups.find(g => g.extraData.index === index)};
    const newSpace = {...groups.find(g => g.extraData.index === newIndex)};

    console.log('o: ', original);
    console.log('n: ', newSpace);

    await Ajax.update('groups', original.id, { extraData: { ...original.extraData, index: newSpace.extraData.index }});
    await Ajax.update('groups', newSpace.id, { extraData: { ...newSpace.extraData, index: original.extraData.index }});

    const res = await Ajax.get('workouts', entry.id);
    setGroups(res.groups);
    setLoading(false);
  }, [entry, groups, loading]);

  if (!entry) {
    return (
      <Loading height='25px' width='25px' />
    )
  }

  if (redirect) {
    return (
      <Redirect push to={redirect} />
    )
  }

  return (
    <main className={classes.main}>

      <div className={classes.row}>
        <Button disabled={!activeEdits} onClick={handleSave}>Save</Button>
        <Button onClick={handleDelete}>Delete</Button>
      </div>

      <div className={classes.row}>
        <DatePicker selectedDate={new Date(updates.date) || new Date()} onChange={(date) => handleDateChange(date)} />

        <FormControlLabel
          control={ <Switch checked={updates.isPublished} onChange={(e) => handleChange(e, true)} name='isPublished' />}
          label='Published'
        />
      </div>

      <div className={classes.row}>
        <TextField className={classes.title} multiline id='Description' placeholder='Description' value={updates.description} name='description' onChange={handleChange} />
      </div>

      <div className={classes.row}>
        <TextField id='Focus' placeholder='Focus Area' value={updates.type} name='type' onChange={handleChange} />
        <TextField id='Equipment' placeholder='Equipment' value={updates.equipment} name='equipment' onChange={handleChange} />
      </div>

      <div className={classes.row}>
        <TextField className={classes.title} id='Tags' placeholder='Tags' value={updates.tags} name='tags' onChange={handleChange} />
      </div>

      <div className={classes.row} style={{ marginBottom: 0, maxWidth: 400 }}>
        <Typography variant='body1'>Length (Equal numbers will be displayed as X mins)</Typography>
      </div>

      <div className={classes.row} style={{ width: 225 }}>
        <TextField id='MinLength' type='number' placeholder='Minimum Length' value={updates.minLength} name='minLength' onChange={handleChange} style={{ minWidth: 0, width: 100 }}/>
        -
        <TextField id='MaxLength' type='number' placeholder='Maximum Length' value={updates.maxLength} name='maxLength' onChange={handleChange} style={{ minWidth: 0, width: 100 }}/>
      </div>

      <div className={classes.row}>
        <TextField className={classes.title} id='Warmup' placeholder='Warm Up Link' value={updates.extraData.warmup} name='warmup' onChange={(e) => handleChange(e, null, true)} />
      </div>

      <div className={classes.row}>
        <TextField className={classes.title} id='Cooldown' placeholder='Cool Down Link' value={updates.extraData.cooldown} name='cooldown' onChange={(e) => handleChange(e, null, true)} />
      </div>

      <div className={classes.row}>
        <Button onClick={handleAddGroup}>Add Set</Button>
      </div>

      { groups.map((group, i) => (
        <Group group={group} groupLength={groups.length} key={i} handleReorder={handleReorder} pageIndex={i + 1} onDelete={() => handleRemoveGroup(group.id)} triggerSave={trigger} resetSave={() => setTrigger(false)} setActiveEdits={setActiveEdits} />
      ))}

    </main>
  )
}

export default Workout;
