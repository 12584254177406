import React from 'react';
import Ajax from '../../Ajax';

import Entries from '../../Components/Entries';

const Workouts = () => {
  const headCells = ['Date', 'Type', 'Equipment', 'Tags'];
  const rowProps = ['date', 'type', 'equipment', 'tags'];

  const defaultValues = {
    date: new Date(),
    description: '',
    tags: '',
    minLength: 15,
    maxLength: 30,
    equipment: '',
    type: '',
  }

  return (
    <Entries 
      headCells={headCells}
      rowProps={rowProps}
      searchFunction={(query) => Ajax.search('workouts', query)} 
      createFunction={() => Ajax.create('workouts', defaultValues)}
      requestData={(start, perPage) => Ajax.getAll('workouts', start, perPage)} 
      requestCount={() => Ajax.count('workouts')} 
      redirectLink='workouts'
    />
  )

}

export default Workouts;