// Action Types

// Users
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

// Navigation
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

// Workouts
export const SET_ALL_EXERCISES = 'SET_ALL_EXERCISES';
export const SET_ALL_WORKOUTS = 'SET_ALL_WORKOUTS';

// Actions

// Users
export function setCurrentUser(payload) {
  return { type: SET_CURRENT_USER, payload };
};

// Navigation
export function setCurrentPage(payload) {
  return { type: SET_CURRENT_PAGE, payload };
};

export function setNotification(payload) {
  return { type: SET_NOTIFICATION, payload };
}

export function toggleSidebar(payload) {
  return { type: TOGGLE_SIDEBAR, payload };
}

// Workouts
export function setAllExercises(payload) {
  return { type: SET_ALL_EXERCISES, payload };
}

export function setAllWorkouts(payload) {
  return { type: SET_ALL_WORKOUTS, payload };
}