export const styles = {
  set: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '20px 0',
    alignItems: 'flex-start',

    '& > h5': {
      marginBottom: '15px'
    }
  },
  titleRow: {
    display: 'flex',
    width: 1000,
    marginBottom: 25,
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h5': {
      marginRight: 15
    },
    '& textarea': {
      width: 500
    }
  },
  icon: {
    color: '#5EC9BE',
    marginTop: 15,
    '&:hover': {
      cursor: 'pointer',
    }
  }
}