import update from 'immutability-helper';
// Users
import { SET_CURRENT_USER } from './actions';
// Navigation
import { SET_CURRENT_PAGE, SET_NOTIFICATION, TOGGLE_SIDEBAR } from './actions';
// Workouts
import { SET_ALL_EXERCISES, SET_ALL_WORKOUTS } from './actions';
import { combineReducers } from 'redux';

const initialUserState = {
  currentUser: {},
};

const initialNavigationState = {
  currentPage: 'users',
  sidebarIsOpen: false,
  notification: {
    msg: '',
    severity: ''
  },
};

const initialWorkoutState = {
  allExercises: null,
  allWorkouts: null,
}

const userReducer = (state = initialUserState, action) => {
  switch(action.type) {
    case SET_CURRENT_USER:
      return update(state, {
        currentUser: {$set: action.payload}
      });
      
    default:
      return state;
  };
};

const workoutReducer = (state = initialWorkoutState, action) => {
  switch(action.type) {
    case SET_ALL_EXERCISES:
      return update(state, {
        allExercises: {$set: action.payload}
      });

    case SET_ALL_WORKOUTS:
      return update(state, {
        allWorkouts: {$set: action.payload}
      });
      
    default:
      return state;
  };
};

const navigationReducer = (state = initialNavigationState, action) => {
  switch(action.type) {
    case SET_CURRENT_PAGE:
      return update(state, {
        currentPage: {$set: action.payload}
      });

    case TOGGLE_SIDEBAR:
      return update(state, {
        sidebarIsOpen: {$set: action.payload}
      });
      
    case SET_NOTIFICATION:
      return update(state, {
        notification: {$set: action.payload}
      });
    default:
      return state;
  };
};

const allReducers = combineReducers({
  user: userReducer,
  navigation: navigationReducer,
  workouts: workoutReducer
});

export default allReducers;