import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentUser, toggleSidebar } from '../../redux/actions.js';

import Ajax from '../../Ajax';
import { useHandleError } from '../../customHooks';

import { Button, TextField, IconButton, InputAdornment, Typography, Container, CircularProgress } from '@material-ui/core';

import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const TOKEN_PROP = 'tl-method-token';
const BUSINESS_TITLE = 'TL Method';
const LOGO_SRC = 'https://cdn.shopify.com/s/files/1/0055/1292/1141/files/Screen_Shot_2018-05-07_at_10.12.12_AM.png';
const FORGOT_PW_LINK = '';

const SignIn = () => {

  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.user.currentUser);
  const handleError = useHandleError(dispatch);
  const history = useHistory();

  const [loggingIn, setLoggingIn] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [form, setForm] = React.useState({
    email: '',
    password: '',
    attempted:  false
  });

  React.useEffect(() => {
    dispatch(toggleSidebar(false));

    return () => {
      dispatch(toggleSidebar(true));
    }
  }, [dispatch])

  React.useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const currentUser = await Ajax.getCurrentUser();

        if (currentUser) {
          dispatch(setCurrentUser(currentUser));
          setLoggingIn(false);

        } else {
          localStorage.removeItem(TOKEN_PROP);
          setLoggingIn(false);
          throw Error('Uh oh, something went wrong!');
        }

      } catch(e) {
          handleError(e);
          localStorage.removeItem(TOKEN_PROP);
      }
    }

    const token = localStorage.getItem(TOKEN_PROP);

    if (token) {
      setLoggingIn(true);

      fetchCurrentUser();
    } else {
      dispatch(setCurrentUser(null));
    }

  }, [dispatch, handleError]);

  const handleSignIn = async (e) => {
    try {
      e.preventDefault();

      let { email, password } = form;
      email  = email.toLowerCase().trim();
      const isValid = isValidEmailAddress(email);

      if (!isValid) throw Error('Invalid email address');

      if (email && password) {
        setLoggingIn(true);
        const res = await Ajax.login({ email, password });
        const { user, token } = res;

        if (!user.isAdmin) throw Error('Not registered as an admin user.');

        localStorage.setItem(TOKEN_PROP, token);
        dispatch(setCurrentUser(user));
        dispatch(toggleSidebar(true));

      } else {
        throw Error('All fields are required.');
      }

      const update = { ...form, ...{ attempted: true }};
      setForm(update);

    } catch (e) {
      setLoggingIn(false);
      localStorage.removeItem(TOKEN_PROP);
      handleError(e);
    }
  }

  const handleChange = (e) => {
    const update = { ...form, ...{[e.target.name]: e.target.value }};
    setForm(update);
  }

  const handleKeyDown = (e) => {
    let code = e.keyCode || e.which;
    if(code === 13) { // Enter Key
      handleSignIn(e);
    }
  }

  if (currentUser && currentUser.email) history.push('/users');

  return (
    <Container component="main" maxWidth="xs">
      <div style={{ marginTop: 0, paddingTop: '10%', display: 'flex', flexDirection: 'column' }}>

        <img style={{ width: 150, height: 150, margin: 'auto' }} alt={BUSINESS_TITLE} src={LOGO_SRC} />

        <Typography component="h1" variant="h5" style={{fontSize: '1.5rem', fontWeight: 'bold', margin: '5% 0 5% 0', textAlign: 'center'}}>
          {`${BUSINESS_TITLE} Admin Dashboard`}
        </Typography>

        <form noValidate style={{ backgroundColor: '#ffffff', padding: '10%', borderRadius: '15%' }}>
          <TextField
            variant="outlined"
            error={form.attempted && form.email.length === 0}
            margin="normal"
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            onChange={handleChange}
            autoComplete="email"
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="outlined"
            error={form.attempted && form.password.length === 0}
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <Button fullWidth onClick={loggingIn ? (e) => e.preventDefault() : handleSignIn}>
            { loggingIn ? <CircularProgress style={{ color: '#ffffff', height: '30px', width: '30px' }} /> : 'Sign In' }
          </Button>

          <Button fullWidth href={FORGOT_PW_LINK}>
            Forgot Password?
          </Button>

        </form>
      </div>
    </Container>
  )


}

export default SignIn;

function isValidEmailAddress(email) {
  const regEx = new RegExp(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);

  return regEx.test(email);
}
