import React from 'react';
import { useSelector } from 'react-redux';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import ExerciseInput from './ExerciseInput';
import ExerciseSearch from './ExerciseSearch';

const Exercise = (props) => {
  const { onChange, onDelete, details: { key, id: exerciseId, count = 0, rest = 0, type } } = props;

  const storedExercises = useSelector(state => state.workouts.allExercises);

  const typeOptions = [
    { label: 'seconds', prop: 'seconds' },
    { label: 'minutes', prop: 'minutes' },
    { label: 'reps', prop: 'reps' },
  ]

  const [associatedExercise, setAssociatedExercise] = React.useState(null);

  React.useEffect(() => {
    if (exerciseId && storedExercises && storedExercises.length) {
      const ing = storedExercises.find(i => i.id === exerciseId);
      setAssociatedExercise(ing);
    }

  }, [exerciseId, storedExercises])

  const handleChange = (name, data) => {
    onChange(key, name, data);
  }

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete(key);
  }

  const handleCopy = (e) => {
    e.stopPropagation();
    const details = props.details;
    localStorage.setItem('tl-method-copied-exercise', JSON.stringify(details));
  }

  return (
    <Accordion TransitionProps={{ unmountOnExit: true }} style={{ width: '1000px' }}>

      <AccordionSummary expandIcon={<ExpandMoreIcon />}>

        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Typography>{associatedExercise?.title || 'New'}</Typography>

          <div style={{ width: '25%', display: 'flex', justifyContent: 'flex-end' }}>
            <FileCopyIcon onClick={handleCopy} />
            <DeleteIcon onClick={handleDelete} />
          </div>
        </div>

      </AccordionSummary>

      <AccordionDetails>

        <ExerciseSearch label='Exercise' name='id' currentSelection={associatedExercise} onChange={handleChange} />

        <ExerciseInput name='count' width={100} label='count' value={count} onChange={handleChange} />

        {
          type!=='reps' && (<ExerciseInput name='rest' width={100} label='Rest time' value={rest} onChange={handleChange} />)
        }

        <ExerciseInput name='type' type='select' width={100} label='type' value={type} onChange={handleChange} options={typeOptions} />

      </AccordionDetails>

    </Accordion>
  )
}

export default Exercise;