import { useDispatch } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import { setNotification, setCurrentPage } from './redux/actions';

export const useDeviceStyles = ({ tabletStyles, mobileStyles, styles, largeStyles }) => {

  if (!mobileStyles) mobileStyles = {...styles};
  if (!tabletStyles) tabletStyles = {...mobileStyles};
  if (!largeStyles) largeStyles = {...styles};

  const isLarge = useMediaQuery('@media only screen and (min-device-width: 1441px)', { noSsr: true });
  const isTablet = useMediaQuery('@media only screen and (min-device-width: 768px) and (max-device-width: 1024px)', { noSsr: true });
  const isMobile = useMediaQuery('@media only screen and (min-device-width: 250px) and (max-device-width: 812px)', { noSsr: true });

  let selectedStyle;
  if (isLarge) {
    selectedStyle = largeStyles;
  } else if (isTablet) {
    selectedStyle = tabletStyles;
  } else if (isMobile) {
    selectedStyle = mobileStyles;
  } else {
    selectedStyle = styles;
  }

  const useStyles = makeStyles(selectedStyle);
  return useStyles(selectedStyle);
}

export const useMediaQueryStyles = ({ tabletStyles, mobileStyles, styles, largeStyles }) => {

  if (!mobileStyles) mobileStyles = {...styles};
  if (!tabletStyles) tabletStyles = {...mobileStyles};
  if (!largeStyles) largeStyles = {...styles};

  const isLarge = useMediaQuery('@media only screen and (min-device-width: 1441px)', { noSsr: true });
  const isTablet = useMediaQuery('@media only screen and (min-device-width: 768px) and (max-device-width: 1024px)', { noSsr: true });
  const isMobile = useMediaQuery('@media only screen and (min-device-width: 250px) and (max-device-width: 812px)', { noSsr: true });

  let selectedStyle;
  if (isLarge) {
    selectedStyle = largeStyles;
  } else if (isTablet) {
    selectedStyle = tabletStyles;
  } else if (isMobile) {
    selectedStyle = mobileStyles;
  } else {
    selectedStyle = styles;
  }

  return selectedStyle;
}

export const useDeviceSize = () => {
  const isLarge = useMediaQuery('@media only screen and (min-device-width: 1441px)', { noSsr: true });
  const isTablet = useMediaQuery('@media only screen and (min-device-width: 768px) and (max-device-width: 1024px)', { noSsr: true });
  const isMobile = useMediaQuery('@media only screen and (min-device-width: 250px) and (max-device-width: 812px)', { noSsr: true });

  if (isLarge ) return 'large';
  if (isTablet) return 'tablet';
  if (isMobile) return 'isMobile';
  return 'desktop';
}

/**
 * Updates Redux store with current page
 */
export const usePageTitle = (title) => {
  const dispatch = useDispatch();

  dispatch(setCurrentPage(title));
}

/**
 * @param {dispatch}
 * @return {function}
 * 
 * Returns a function that accepts an Error and updates notification in Redux store
 */
 export const useHandleError = (dispatch) => {

  return (e, subMessage = null) => {
    const errMessage = e.response && e.response.data && e.response.data.message
      ? e.response.data.message
      : e.message;
  
    dispatch(setNotification({ msg: errMessage, subMessage: subMessage || 'Please try again or contact info@tlmethod.com', severity: 'error' }));
  }
}