import React from 'react';
import { useDeviceStyles } from '../../customHooks';

import { TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useDebounce } from 'react-use';

const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    width: 500,
    margin: '15px 0',
  },
  input: {
    width: '90%'
  }
}

const Search = ({ onChange, searchFunction, onSearch }) => {
  const classes = useDeviceStyles({ styles });

  const [searchVal, setSearch] = React.useState('');

  // Only requests the search if the field is non-empty and
  // hasn't been changed for 500 ms
  useDebounce(async () => {
    onSearch(searchVal);
    const res = await searchFunction(searchVal);
    onChange(res);
  }, searchVal ? 500 : null, [searchVal, searchFunction, onChange, onSearch]);

  return (
    <div className={classes.wrapper}>
      <SearchIcon  />
      <TextField
        className={classes.input}
        variant='filled'
        value={searchVal}
        onChange={(e) => setSearch(e.target.value)}
      />
    </div>
  )
}

export default Search;
