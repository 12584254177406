import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setAllExercises } from '../../redux/actions';
import Ajax from '../../Ajax';

import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const ExerciseSearch = ({ label, currentSelection, onChange, name }) => {

  const storedExercises = useSelector(state => state.workouts.allExercises);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const fetchAllExercises = async () => {
      const res = await Ajax.getAll('exercises');
      dispatch(setAllExercises(res));
    }

    if (!storedExercises) fetchAllExercises();

  }, [dispatch, storedExercises])

  const handleChange = (e, val) => {
    const selection = storedExercises.find(ing => ing.title === val);
    if (selection) {
      onChange(name, selection.id);
    }
  }

  return (
    <Autocomplete
      freeSolo
      onChange={handleChange}
      value={currentSelection?.title || ''}
      options={storedExercises.map(i => i.title)}
      renderInput={(params) => (
        <TextField {...params} label={label} margin="normal" variant="outlined" />
      )}
    />
  )
}

export default ExerciseSearch;
