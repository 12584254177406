import React from 'react';
import DateFnsUtils from '@date-io/date-fns';

import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const DatePicker = ({ selectedDate, onChange }) => {

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker 
        label='Published Date'
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        margin="normal"
        value={selectedDate}
        onChange={onChange}
        autoOk
      />
    </MuiPickersUtilsProvider>
  )
}

export default DatePicker;